var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cog-abuse cog-wrapper" }, [
    _c("hr"),
    _vm._v(" "),
    _c("ul", [
      _c("li", [
        _c("a", { attrs: { target: "_blank", href: _vm.reportAbuseUrl } }, [
          _vm._v(_vm._s(_vm.$resource("public-report-abuse-link-text")))
        ])
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { target: "_blank", href: _vm.baseUrl + "terms" } }, [
          _vm._v(_vm._s(_vm.$resource("public-terms-of-service-link-text")))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }